<template>
	<div class="join">
		<div class="banner">
			<img src="https://image.ceekee.com/pc/tripwise/20210121.png">
		</div>
		
		<div class="sectionOne">
			<div class="sectionOne_info">
				<p>让出差变得更简单<br>致力于改变传统的企业差旅</p>
				<div class="infoLeft">
					<span>苏州大管家信息科技有限公司旗下品牌差旅管家Tripwise为企业提供卓越的差旅管理和服务体验。我们引四海栋梁，纳天下英才，关注每一位员工的发展，
					为员工提供激发创造力的工作环境和顶级的学习平台。我们不断完善公司体系，打造一支富有激情、坚守信仰、朝气蓬勃的团队。</span>
					<span>这里吸引你的，或许不只是多元化的团队，扁平的管理，还有开放的氛围和广阔的前景...这里将是你职业
					加速的优质跑道,为了你和苏州大管家信息科技有限公司共同美好的明天，让我们一起携手谱写新篇章!</span>
				</div>
			</div>	
		</div>
		
		<div class="sectionTwo">
			<img src="https://image.ceekee.com/pc/tripwise/20200109_8.png">
			<div class="sectionTwo_info">
				<div class="infoLi one">
					<p>商务经理</p>
					<span>将更优秀的企业差旅管理方案推广给更多的企业，告别传统差旅，享受更好的差旅管理</span>
					<a @click="showDetaild(0)">查看详情-></a>
				</div>
				<div class="infoLi two">
					<p>生态合作经理</p>
					<span>组织或参加合作伙伴的相关市场活动，获得潜在商机，让企业告别传统差旅，享受更好的差旅管理</span>
					<a @click="showDetaild(1)">查看详情-></a>
				</div>
				<div class="infoLi three">
					<p>产品/研发</p>
					<span>打造伟大的产品，帮助中国数千万企业改变传统差旅的体验</span>
					<a @click="showDetaild(2)">查看详情-></a>
				</div>
				<div class="infoLi four">
					<p>客服专员</p>
					<span>与客户合作共赢，与客户共同成长，给客户带来极致客户体验，为企业的差旅保驾护航</span>
					<a @click="showDetaild(3)">查看详情-></a>
				</div>
				<div class="infoLi five">
					<p>其他岗位</p>
					<span>每一个岗位，都在散发属于他们的光。每一个梦想，都会在差旅管家实现</span>
					<a @click="showDetaild(4)">查看详情-></a>
				</div>
			</div>	
		</div>		
		<Contact></Contact>
	</div>
</template>

<script>
	import Contact from "@/components/Contact.vue";
	export default {
		data() {
			return {
				dialogVisible:false,
				jobDetail:'dwdwd',
				jobList:[
					`岗位职责：<br>1、负责企业客户开发工作，执行销售计划并完成相应的业绩指标；<br>2、定期和不定期拜访企业客户，挖掘客户企业差旅管理，介绍公司具有竞争力的产品及服务；<br>3、与企业客户签订服务合同，
					协助服务方案的前期筹备及执行开通；<br>4、协助运营团队进行签约客户的运营和维护工作，维持良好客户关系；<br><br>职位要求：<br>1、本科及以上学历，3 年以上商务工作经验，有企业级SaaS产品销售经验优先；
					<br>2、具备较强的团队协作以及服务意识，良好的公关技巧及客户关系维护能力；<br>3、形象气质佳，有良好的演讲以及文案材料处理能力；<br>4、勇于开拓，做事积极主动，思路清晰，独立工作能力强，能够承受较
					大工作压力；<br>5、优秀的人际协调能力，沟通能力。`,
					`岗位职责：<br>1、开发维护公司生态合作伙伴，建立长期有效的合作；<br>2、维护生态合作伙伴关系，获取销售机会；<br>3、组织或参加合作伙伴的相关市场活动，获得潜在商机；<br>4、制定项目计划并落地实施。<br>
					<br>职位要求：<br>1、本科及以上学历，形象气质良好；<br>2、具有2-5年以上工作经验，企业财务服务领域、saas或者云服商旅服务等销售经验者优先；<br>3、具备很强的沟通能力和说服能力；<br>4、有责任心，能承
					受较大的工作压力。`,
					`岗位职责：<br>1、负责移动端、pc端产品的规划和设计，主要包含：<br>（1）酒店、机票、火车票、用车产品全流程优化<br>（2）审批、差标、组织架构、结算、预算等产品优化<br>2、分析用户需求，绘制产品原型图，
					编写相关需求文档；<br>3、收集用户数据，对数据进行整理分析，持续优化产品；<br>4、定期输出竞品分析报告，关注行业内其他产品方案并研究；<br>5、上级领导交付的其他任务。<br>任职要求：<br>1、三年以上工作
					经验，有丰富的2B产品运营工作经验；<br>2、具备优秀的产品和市场分析能力；<br>3、具备优秀的沟通能力和团队协作精神，有较强的主人翁意识，能独立发起和承担项目工作；<br>4、对商旅或费控类产品有深刻理解，具
					上述领域工作经验者优先。`,
					`岗位职责：<br>1、处理客户相关使用和售后问题。负责处理400电话来电、微信客户的订单问题：主要为酒店退订，机票、火车票退改或 APP使用过程中出现的其他问题；<br>2、负责对接企业客户的资料整理及存档，
					维护客户，促进长期、良好、稳固的客户关系；<br>3、根据客户需求和市场变化，对公司产品和服务提出合理化改进建议；<br>4、完成上级领导安排的其他工作。<br>任职要求：<br>1、有相关经验者优先；<br>2、熟练运
					用office及相关办公软件。`,
					`岗位职责：<br>1、酒店，机票等供应链资源管理，维护供应商关系；<br>2、围绕产品竞争力，丰富度，产品收益的核心项目推进；<br>3、协议酒店，机票托管签约上架维护；<br>4、优化酒店，机票等资源，采购专属政
					策，维护并跟进资源售卖情况；<br>5、每日资源排查，包括不限于比价，数据分析，价格调整等，并跟进反馈；<br>6、协同处理资源端问题，推动解决合作中存在的问题。<br><br>任职要求：<br>1、具备较强的工作责任
					心，出色的语言表达能力与沟通协调能力；<br>2、具备独立处理复杂问题和危急事件的能力；<br>3、具备较强的工作积极性和主动服务的意识；<br>4、熟练使用office办公软件。`			
				]
			}
		},
		components: {
		  Contact
		},
		methods:{
			showDetaild(id){
				this.$alert(this.jobList[id], '招聘要求', {
				  dangerouslyUseHTMLString: true,
				  showConfirmButton:false,
				  closeOnClickModal:true,
				  customClass:'showDetaild',
				  lockScroll:false
				});
			}
		},
		destroyed() {
			let closeBtn = document.body.getElementsByClassName("el-icon-close")[0]
			closeBtn.click()
		}
	}
</script>

<style lang="less">
	.showDetaild{
		width:600px!important;
	}	
	.join{
		.banner{
			width: 100%;
			overflow: hidden;
			background-size: cover;
			img{
				width: 100%;
			}
		}
		.sectionOne{
			overflow: hidden;
			background: url(https://image.ceekee.com/pc/tripwise/20200109_6.png) right 0 no-repeat;
			background-size: contain;
			margin:60px auto;
			.sectionOne_info{
				width: 40%;
				margin: 60px 0 60px 10%;
				padding: 4% 6% 4% 6%;
				background: url(https://image.ceekee.com/pc/tripwise/20200109_5.png) no-repeat;
				background-size: cover;
				p{
					font-size: 40px;
					line-height: 60px;
					letter-spacing: 0px;
					margin-bottom: 20px;
				}
				.infoLeft{
					margin-top: 10px;
					color: #999;
					font-size: 20px;
					letter-spacing: 0;
					line-height: 40px;
					span{
						display: block;
						text-indent: 40px;
					}
				}				
			}
		}
		.sectionTwo{
			width: 100%;
			padding-bottom: 94px;
			overflow: hidden;
			background: url(https://image.ceekee.com/pc/tripwise/20200109_7.png) center no-repeat;
			background-size: cover;
			&>img{
				width: 22%;
				margin: 70px auto 50px;
				display: block;
			}
			.sectionTwo_info{
				width: 95%;
				margin: 0 auto;
				.infoLi{
					width: 20%;
					padding: 0 1% 50px 2.5%;
					box-sizing: border-box;
					float: left;
					background: url(https://image.ceekee.com/pc/tripwise/20200109_9.png) 0 center no-repeat;
					background-size: cover;
					&.two{
						background: url(https://image.ceekee.com/pc/tripwise/20200109_10.png) 0 center no-repeat;
						background-size: cover;
					}
					&.three{
						background: url(https://image.ceekee.com/pc/tripwise/20200109_11.png) 0 center no-repeat;
						background-size: cover;
					}
					&.four{
						background: url(https://image.ceekee.com/pc/tripwise/20200109_12.png) 0 center no-repeat;
						background-size: cover;
					}
					&.five{
						background: url(https://image.ceekee.com/pc/tripwise/20200109_13.png) 0 center no-repeat;
						background-size: cover;
					}
					p{
						&::before{
							content: '';
							width: 50px;
							height: 8px;
							background: #FFBE2E;
							display: block;
							margin: 200px 0 15px;
						}
						font-size: 25px;
						line-height: 45px;
						color: #222222;
					}
					span{
						font-size: 16px;
						line-height: 30px;
						color: #666666;
						margin: 0 0 40px;
						display: block;
						min-height: 150px;
					}
					a{
						color: #999999;
						cursor: pointer;
						font-size: 18px;
						line-height: 33px;
					}
				}
			}
		}
		
		@media screen and (max-width:1620px){
			.sectionOne .sectionOne_info{width: 45%;}
			.sectionOne .sectionOne_info p{font-size: 36px;line-height: 50px;margin-bottom: 15px;} 
		}

		
		@media screen and (max-width:1440px){
			.sectionOne .sectionOne_info p{font-size: 33px;line-height: 45px;} 
			.sectionOne .sectionOne_info .infoLeft{font-size: 18px;line-height: 34px;}
		}	
		
		@media screen and (max-height:900px){
			.sectionOne .sectionOne_info p{font-size: 33px;line-height: 45px;}
			.sectionOne .sectionOne_info .infoLeft{font-size: 18px;line-height: 34px;}
		}
		
		@media screen and (max-width:1300px){
			.sectionOne .sectionOne_info p{font-size: 30px;line-height: 45px;} 
			.sectionOne .sectionOne_info .infoLeft{font-size: 16px;line-height: 34px;}
		}	
	}
</style>